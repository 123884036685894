import { useTitleTemplate, useTitle, useMeta, useLang } from 'hoofd'

interface Props {
  pageTitle: string
  metaDescription?: string
}

export const SEO = ({ pageTitle, metaDescription }: Props) => {
  useLang('en')
  useTitleTemplate('%s | Surgical Associates Hawaii')
  useTitle(pageTitle)
  useMeta({ name: 'description', content: metaDescription })

  return null
}
